import { createValidationObjectSchema } from '@/utils/validations';

export const Agency = createValidationObjectSchema({
  business_name: {
    required: true,
  },
  discount: {
    required: true,
  },
  description: {
    required: true,
  },
  business_phone_number: {
    required: true,
  },
  business_website: {
    required: true,
  },
});
